<template>
  <div
    :class="{
      'editable-input': className === null,
      'editable-inputClass': className === 'editable-inputClass',
      'editable-input-fiche': className === 'editable-input-fiche'
    }"
  >
    <div
      class="edit-value"
      v-if="
        (editing && this.editable === true && this.devalider === true) ||
          (editing && this.editable === false && this.devalider === false)
      "
    >
      <div id="selectInput" v-if="editableType === 'select'">
        <multiselect
          v-model="updateV"
          :showLabels="showLabels"
          placeholder="Rechercher"
          track-by="full_name"
          label="full_name"
          :options="optionsSelect"
          :searchable="true"
          select-label=""
          :max-height="150"
          class="selectDefault"
          :class="{
            selectAnomalie: item.anomalies,
            selectAlert: !item.anomalies,
            positionClass: positionSelect
          }"
          :allow-empty="allowEmpty"
        >
          <span slot="noResult">Aucun {{ champName }} trouvé </span>
          <template
            slot="option"
            slot-scope="{ option }"
            v-if="formate == 'regieFacture'"
            ><strong
              >{{ option.full_name | statutFactureFormat(formate) }}
            </strong>
          </template>
          <template
            slot="singleLabel"
            slot-scope="{ option }"
            v-if="formate == 'regieFacture'"
            ><strong
              >{{ option.full_name | statutFactureFormat(formate) }}
            </strong>
          </template>
          <span slot="noOptions">La liste est vide</span>
        </multiselect>
      </div>
      <div id="selectInput" v-if="editableType === 'selectInput'">
        <multiselect
          v-model="updateV"
          :showLabels="showLabels"
          placeholder="Rechercher"
          track-by="id"
          label="full_name"
          :options="optionsSelect"
          :searchable="true"
          select-label=""
          :max-height="150"
          class="selectDefault"
          :class="{
            selectAnomalie: item.anomalies,
            selectAlert: !item.anomalies,
            positionClass: positionSelect
          }"
          :allow-empty="allowEmpty"
        >
          <span slot="noResult">Aucun {{ champName }} trouvé </span>
          <span slot="noOptions">La liste est vide</span>
        </multiselect>
      </div>
      <template v-if="editableType === 'datetime'">
        <date-picker
          value-type="format"
          :format="type == 'date' ? 'DD-MM-YYYY' : 'YYYY-MM-DD HH:mm'"
          :type="type"
          :value="updateV"
          @input="handleDateTimePicker"
          class="inputDate"
          :class="{
            Color: defaultColor == true,
            'min-width-data-time': type == 'datetime' && showIconEdit == false
          }"
        ></date-picker>
      </template>

      <input
        v-if="editableType === 'input'"
        class="input"
        :class="{
          bgAnomalie: item.anomalies,
          bgAlert: !item.anomalies,
          Color: defaultColor == true
        }"
        :type="type"
        v-model="updateV"
        @keyup.enter="update"
        @keyup.esc="edit(false)"
        ref="autofocus"
      />
      <div class="edit-action">
        <div class="action-icon validate" @click="update">
          <font-awesome-icon icon="check" class="fa-2x bell1" />
        </div>
        <div class="action-icon cancel" @click="cancel">
          <font-awesome-icon icon="times" class="fa-2x bell1" />
        </div>
      </div>
    </div>
    <div class="show-value" v-else @dblclick="edit(true)" :class="classToUse">
      <span :style="withFixe ? 'word-wrap: break-word; width:100px  ' : ''">
        {{ value | statutFactureFormat(formate) }}{{ uniteMontant }}</span
      >
      <font-awesome-icon
        class="show-view-pencil ml-2"
        :class="{ 'show-editable-icon': showIconEdit == true }"
        icon="pencil-alt"
        v-if="
          (this.editable === true && this.devalider === true) ||
            (this.editable === false && this.devalider === false)
        "
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect';
import 'vue2-datepicker/index.css';
export default {
  props: {
    champName: { required: true },
    withFixe: { default: false },

    item: { required: true },
    value: { required: true },
    type_ste: { required: false },
    type: { default: 'number' },
    semaine: { default: null },
    depot: { default: null },
    typefilter: { default: null },
    anomalies: { type: Array },
    anom: { default: null },
    searchNum: { dafault: null },
    filiale: { dafault: null },
    typeData: { default: null },
    defaultColor: {
      default: false
    },
    updateFunction: { required: true },
    year: { default: null },
    editableType: {
      type: String,
      default: 'input'
    },
    optionsSelect: {
      type: Array
    },
    editable: {
      default: true
    },
    devalider: {
      default: true
    },
    className: {
      default: null
    },
    label: {
      required: false
    },
    positionSelect: {
      default: false
    },
    formate: {
      default: false
    },
    month: {
      required: false
    },
    switchMonth: {
      required: false
    },
    showIconEdit: {
      default: true
    },
    allowEmpty: { default: true },
    uniteMontant: { default: '' },
    filterObject: { required: false },
    showLabels: { default: true },
    classToUse: { defaunt: '' }
  },
  data() {
    return {
      editing: false,
      updateValue:
        this.editableType == 'select' ? { full_name: this.value } : this.value
    };
  },
  methods: {
    handleDateTimePicker(data) {
      this.updateValue = data;
    },
    edit(payload) {
      if (payload == true && this.editableType == 'select') {
        this.updateValue = { full_name: this.value };
      }
      if (payload == true && this.editableType == 'selectInput') {
        this.optionsSelect.map(element => {
          if (element.full_name == this.value) {
            this.updateValue = element;
          }
        });
      }
      this.editing = payload;
    },
    cancel() {
      this.editing = false;
      if (this.editableType === 'selectInput') {
        this.updateValue = { id: this.value };
      } else if (this.editableType === 'select') {
        this.updateValue = { full_name: this.value };
      } else {
        this.updateValue = this.value;
      }
    },
    async update() {
      if (
        (this.editableType !== 'select' && this.value != this.updateValue) ||
        (this.editableType === 'select' &&
          this.value != this.updateValue?.full_name) ||
        (this.label &&
          this.editableType === 'select' &&
          this.value != this.updateValue?.value) ||
        this.editableType === 'selectInput'
      ) {
        var value = null;
        if (this.editableType === 'select') {
          if (this.label) {
            value = this.updateValue?.value;
          } else {
            value = this.updateValue?.full_name;
          }
        } else if (this.editableType === 'selectInput') {
          if (this.label) {
            value = this.updateValue;
          } else {
            value = this.updateValue?.full_name;
          }
        } else {
          value = this.updateValue;
        }
        await this.updateFunction({
          value: value,
          row: this.item,
          column: this.champName,
          type_ste: this.type_ste,
          type: this.typefilter,
          semaine: this.semaine,
          depot: this.depot,
          anomalies: this.anomalies,
          anom: this.anom,
          typeData: this.typeData,
          annee: this.year,
          name: this.searchNum,
          filiale: this.filiale,
          month: this.month,
          switchMonth: this.switchMonth,
          filterObject: this.filterObject
        });
        if (this.editableType === 'select') {
          this.updateValue = { full_name: this.value };
        } else {
          this.updateValue = this.value;
        }
      }
      this.editing = false;
    }
  },
  computed: {
    updateV: {
      get() {
        if (this.editableType === 'selectInput' && this.updateValue != null) {
          if (
            this.optionsSelect.find(
              element => element.full_name == this.updateValue.full_name
            ) == undefined
          ) {
            return '';
          } else {
            this.optionsSelect.map(element => {
              if (element.full_name == this.updateValue.full_name) {
                this.updateValue = element;
              }
            });
            return this.updateValue;
          }
        } else if (this.editableType === 'select') {
          if (
            this.optionsSelect.find(
              element => element.full_name == this.updateValue.full_name
            ) == undefined
          ) {
            return '';
          } else {
            return this.updateValue;
          }
        } else if (this.editableType === 'datetime') {
          return this.updateValue;
        }
        return this.value;
      },
      set(updateV) {
        if (this.editableType == 'selectInput') {
          this.updateValue = updateV;
        }
        this.updateValue = updateV;
      }
    }
  },
  components: { Multiselect, DatePicker },
  filters: {
    statutFactureFormat: function(value, formate) {
      if (formate == 'regieFacture') {
        switch (value) {
          case 'en attend':
            return 'En cours de validation';
          case 'refuse':
            return 'Réfusé';
          case 'annuler':
            return 'Annuler';
          case 'valid':
            return 'Appel à facturation validé';
          case 'encore de verification':
            return 'En cours de vérification';
          case 'facture valid':
            return 'Facture validé';
          case 'facture refuse':
            return 'Facture réfusé';
          case 'encore de paiement':
            return 'En cours de paiement';
          case 'paye':
            return 'Facture payée';
          case 'a paye':
            return 'Facture à payer';
          case 'facture non validée':
            return 'Facture non validée';
          case 'annulee':
            return 'Facture annulée';
          case 'Annulée':
            return 'Facture annulée';
          case 'passive':
            return 'Facture Passive';
          default:
            if (value) {
              return value.replace(/_/g, ' ');
            } else {
              return value;
            }
        }
      } else {
        return value;
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.editable-input {
  .edit-value {
    display: flex;
    .input {
      text-align: center;
      outline: 0;
      border: 1px solid #ded3d5;
      font-weight: 400;
      font-size: 12px;
      min-width: 63px;
      width: 100%;
    }
    .bgAnomalie {
      background-color: #f2e5e7;
    }
    .bgAlert {
      background-color: #dce6de;
    }
    .Color {
      background-color: transparent;
      .mx-input {
        background-color: transparent !important;
        height: 41px;
      }
    }
    .edit-action {
      .action-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin: 0 1px;
        margin-left: 1px;
        margin-right: 1px;
        border: 1px solid #ded3d5;
        font-size: 7px;
        cursor: pointer;

        &.validate {
          color: #4d4bac;
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
          &:hover {
            color: #ffffff;
            background-color: #4d4bac;
          }
        }

        &.cancel {
          color: #dc3c3c;
          &:hover {
            color: #ffffff;
            background-color: #dc3c3c;
          }
        }
      }
    }
  }
  .show-value {
    min-width: 10px;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .show-view-pencil {
      color: #9e9e9e;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    .show-editable-icon {
      display: inline-block;
      opacity: 1;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
}
.editable-inputClass {
  height: 30px;
  display: contents;
  .edit-value {
    display: inline;
    width: 166px;

    .input {
      text-align: center;
      outline: 0;
      border: 1px solid #ded3d5;
      font-weight: 400;
      font-size: 12px;
    }
    .Color {
      background-color: transparent;
    }
    .edit-action {
      margin-left: 16%;
      margin-top: 2px;
      display: flex;
      .action-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin: 0 1px;
        margin-left: 1px;
        margin-right: 1px;
        border: 1px solid #ded3d5;
        font-size: 7px;
        cursor: pointer;

        &.validate {
          color: #4d4bac;
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
          &:hover {
            color: #ffffff;
            background-color: #4d4bac;
          }
        }

        &.cancel {
          color: #dc3c3c;
          &:hover {
            color: #ffffff;
            background-color: #dc3c3c;
          }
        }
      }
    }
  }
  .show-value {
    min-width: 10px;
    min-height: 100%;
    justify-content: flex-end;
    cursor: pointer;
    .show-view-pencil {
      color: #9e9e9e;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
}
.editable-input-fiche {
  .edit-value {
    display: inline;
    width: 166px;

    .input {
      text-align: center;
      outline: 0;
      border: 1px solid #ded3d5;
      font-weight: 400;
      font-size: 12px;
    }
    .Color {
      background-color: transparent;
    }
    .edit-action {
      margin-top: 2px;
      display: flex;
      justify-content: center;
      .action-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin: 0 1px;
        margin-left: 1px;
        margin-right: 1px;
        border: 1px solid #ded3d5;
        font-size: 7px;
        cursor: pointer;

        &.validate {
          color: #4d4bac;
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
          &:hover {
            color: #ffffff;
            background-color: #4d4bac;
          }
        }

        &.cancel {
          color: #dc3c3c;
          &:hover {
            color: #ffffff;
            background-color: #dc3c3c;
          }
        }
      }
    }
  }
  .show-value {
    min-width: 10px;
    min-height: 10px;
    justify-content: flex-end;
    cursor: pointer;
    .show-view-pencil {
      color: #9e9e9e;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
}
</style>

<style lang="scss">
.selectAnomalie {
  .multiselect__tags,
  .multiselect__single,
  .multiselect__input {
    background-color: #f2e5e7;
  }
}
.selectAlert {
  .multiselect__tags,
  .multiselect__single,
  .multiselect__input {
    background-color: #dce6de;
  }
}
.positionClass {
  .multiselect__content-wrapper {
    position: relative !important;
  }
}
.selectDefault {
  min-width: 80px;
  .multiselect__tags,
  .multiselect__single,
  .multiselect__input {
    background-color: transparent;
  }
}
.inputDate {
  width: 100%;
  min-width: 122px;
  .mx-input {
    background-color: transparent;
    height: 41px;
  }
}
.min-width-data-time {
  min-width: 190px;
}
.multiselect--active {
  z-index: 50;
}

#selectInput {
  width: 89%;

  .multiselect__option {
    line-height: 10px;
    padding: 9px;
    min-height: 15px;
    font-size: 10px;
  }
  .multiselect__option:after {
    line-height: 26px;
    font-size: 10px;
    padding-right: 24px;
    padding-left: 7px;
  }
  .multiselect__select {
    width: 23px;
    height: 25px;
    padding-top: 24px;
    height: 25px;
  }
  .multiselect__element {
    font-size: 10px;
  }
  .multiselect__tags {
    border: 1px solid #ced4da;
    font-size: 11px;
    min-height: 31px;
    padding: 10px 27px 10px 5px;
    width: 100%;
    margin: auto;
    .multiselect__single {
      font-size: 10px;
      padding-left: 0px;
      margin-bottom: 0px;
    }
    .multiselect__input {
      font-size: 10px;
    }
  }
}
</style>
